import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { CookieContext } from 'shared/components/CookieContextProvider';
import { tagManagerScript } from 'shared/googleTagManager';
import { segmentScript } from 'shared/segment';
import { trustedShopsScript } from 'shared/services/trustedShops/fetchRatings';

const DynamicCookieBar = dynamic(
  () => import('@heycater/cookie-bar').then(({ CookieBar }) => CookieBar),
  {
    ssr: false,
  }
);

export default function CookieBar() {
  const { locale } = useRouter();
  const { detailsOpen, setDetailsOpen, setConsentGiven } = useContext(
    CookieContext
  );

  const onConsentGiven = () => {
    setConsentGiven(true);
    tagManagerScript();
    segmentScript();
    trustedShopsScript();
  };

  const onConsentWithdrawn = () => {
    location.reload();
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_DISABLE_COOKIE_BAR !== 'true' && (
        <DynamicCookieBar
          detailsOpen={detailsOpen}
          setDetailsOpen={setDetailsOpen}
          locale={locale}
          onConsentGiven={onConsentGiven}
          onConsentWithdrawn={onConsentWithdrawn}
          backdrop
        />
      )}
    </>
  );
}
