import React, { createContext, useState } from 'react';
import { GDPR_COOKIE_CONSENT_NAME } from '@heycater/cookie-bar';
import Cookies from 'js-cookie';

import CookieBar from 'shared/components/CookieBar';

export const CookieContext = createContext({});

export default function CookieContextProvider({ children }) {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [consentGiven, setConsentGiven] = useState(
    () => Cookies.get(GDPR_COOKIE_CONSENT_NAME) === 'true'
  );

  return (
    <CookieContext.Provider
      value={{ detailsOpen, setDetailsOpen, setConsentGiven, consentGiven }}
    >
      {children}
      <CookieBar />
    </CookieContext.Provider>
  );
}
